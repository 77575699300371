import App from "framework/components/App";
import AuthenticatedRoute from "framework/AuthenticatedRoute";
import OrganisationNewProjectController from "organisation/OrganisationNewProjectController";

export default function Page() {
    return (
        <App>
            <AuthenticatedRoute>
                <OrganisationNewProjectController />
            </AuthenticatedRoute>
        </App>
    );
}
